import thunk from 'redux-thunk';
import multi from 'redux-multi';
import { createStore, compose, applyMiddleware } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

// import tron from './reactotronConfig';
import reducers from '../reducers';

const middlewares = [multi, thunk];

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['StoreReducer'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const enhancers = compose(applyMiddleware(...middlewares));

// ? compose(
//     applyMiddleware(...middlewares),
//     tron.createEnhancer(),
//   )
// :

const store = createStore(persistedReducer, {}, enhancers);
// (process.env.NODE_ENV === 'development') === true
//   ? createStore(persistedReducer, {}, enhancers)
//   : createStore(persistedReducer, applyMiddleware(...middlewares));

const persistor = persistStore(store);

export { store, persistor };
