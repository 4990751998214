/*eslint-disable*/
import React from 'react';

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

class Login extends React.Component {
  render() {
    return (
      <>
        <Container fluid>
          <footer className=" pt-0">
            <Row className="align-items-center">
              <Col className="md-auto">
                <div className="copyright text-center text-muted text-sm-center text-xs">
                  Painel de Controle de Usuários - GTA Torcidas v2.0 © {new Date().getFullYear()}
                  {' -'}
                  <a
                    className="font-weight-bold ml-1"
                    href="https://www.gtatorcidas.net/forum/profile/1-duduh1994/"
                    target="_blank">
                    DuDuH
                  </a>
                </div>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default Login;
