import React, { useState, useRef } from 'react';
// nodejs library that concatenates classes
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';

// Libraries
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import Swal from 'sweetalert2';

import ReCAPTCHA from 'react-google-recaptcha';

// core components
import AuthHeader from '../../components/Headers/AuthHeader';

// Other imports
import { LoginAction } from '../../actions';
import { loginValidator } from '../../utils/validators';

function Login({ history }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const captchaRef = useRef();

  const myFormik = useFormik({
    initialValues: {
      username: '',
      password: '',
      captchaKey: '',
    },
    // eslint-disable-next-line no-use-before-define
    onSubmit: submitForm,
    validationSchema: loginValidator,
  });

  function submitForm(values) {
    setIsLoading(true);
    dispatch(
      LoginAction(values, function callback(retorno) {
        if (retorno.forbidden) {
          Swal.fire({
            icon: 'error',
            title: retorno.message,
            showConfirmButton: true,
            confirmButtonText: `Sim Senhor!`,
            // timer: 1500,
          });
          setIsLoading(false);
          captchaRef.current.reset();

          return;
        }
        if (retorno.sucesso) {
          Swal.fire({
            icon: 'success',
            title: retorno.message,
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            setIsLoading(false);
            captchaRef.current.reset();
          }, 500);
          setTimeout(() => {
            history.replace('/painel');
          }, 900);
        } else {
          setTimeout(() => {
            setIsLoading(false);
            captchaRef.current.reset();
          }, 800);
          Swal.fire({
            icon: 'error',
            title: retorno.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }),
    );
  }

  const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;

  return (
    <>
      <AuthHeader title="Bem vindo!" lead="Painel de Controle de Usuários - GTA Torcidas" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted">
                  <span>
                    <img alt="..." src="https://i.imgur.com/6MFl4GQ.png" width="95" height="70" />
                  </span>
                </div>
                <div className="text-center text-muted mb-4">
                  <small>Entre com suas credênciais do servidor</small>
                </div>
                <Form role="form" className="needs-validation" onSubmit={myFormik.handleSubmit}>
                  <FormGroup
                    className={classnames('mb-3', {
                      focused: false,
                    })}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Nick In-Game"
                        type="text"
                        name="username"
                        onFocus={() => {}}
                        onBlur={() => myFormik.setFieldTouched('username')}
                        onChange={e => myFormik.setFieldValue('username', e.target.value)}
                        value={myFormik.values.username}
                        invalid={!!myFormik.errors.username && !!myFormik.touched.username}
                      />
                    </InputGroup>
                    <div className="invalid-feedback input-group text-right ">
                      {myFormik.errors.username}
                    </div>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: false,
                    })}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Senha In-Game"
                        type="password"
                        onFocus={() => {}}
                        onBlur={() => myFormik.handleBlur('password')}
                        onChange={e => myFormik.setFieldValue('password', e.target.value)}
                        value={myFormik.values.password}
                      />
                    </InputGroup>
                    {myFormik.touched.password && myFormik.errors.password && (
                      <div className="invalid-feedback input-group text-right">
                        {myFormik.errors.password}
                      </div>
                    )}
                  </FormGroup>
                  {/* <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor=" customCheckLogin">
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div> */}
                  <div>
                    <div className="recaptcha">
                      <ReCAPTCHA
                        ref={captchaRef}
                        sitekey={recaptchaKey}
                        onChange={e => myFormik.setFieldValue('captchaKey', e)}
                      />
                    </div>
                    {myFormik.touched.captchaKey && myFormik.errors.captchaKey && (
                      <div className="invalid-feedback input-group text-left">
                        {myFormik.errors.captchaKey}
                      </div>
                    )}
                  </div>

                  <div className="text-center">
                    <Button className="my-4" color="info" type="submit">
                      {isLoading ? (
                        <ScaleLoader
                          loading
                          color="white"
                          radius="10px"
                          size="40px"
                          margin="2px"
                          height="20px"
                          width="2px"
                        />
                      ) : (
                        'Acessar'
                      )}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            {/* <Row className="mt-3">
              <Col className="text-center">
                <a className="text-light" href="#duduzera" onClick={e => e.preventDefault()}>
                  <small>Esqueceu sua senha?</small>
                </a>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
