import React from 'react';
// react library for routing
import { Route, Switch, Redirect } from 'react-router-dom';
// core components
import PainelNavBar from '../components/Navbars/PainelNavBar';
import AdminFooter from '../components/Footers/AdminFooter';
import Sidebar from '../components/Sidebar/Sidebar';

import { privateRoutes } from '../routes/routes';

class Admin extends React.Component {
  state = {
    sidenavOpen: true,
  };
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/painel') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      }
      return null;
    });
  };

  getBrandText = path => {
    for (let i = 0; i < privateRoutes.length; i++) {
      if (
        this.props.location.pathname.indexOf(privateRoutes[i].layout + privateRoutes[i].path) !== -1
      ) {
        return privateRoutes[i].name;
      }
    }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal

  toggleSidenav = e => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };

  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf('admin/alternative-dashboard') === -1
      ? 'dark'
      : 'light';
  };

  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={privateRoutes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: '/',
            imgSrc: 'https://i.imgur.com/6MFl4GQ.png',
            imgAlt: '...',
          }}
        />
        <div className="main-content" ref="mainContent" onClick={this.closeSidenav}>
          <PainelNavBar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(privateRoutes)}
            <Redirect from="*" to="/painel/loja" />
            <Redirect from="/" to="/painel/loja" />
          </Switch>

          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

export default Admin;
