import { LOGIN_RESULT, SET_INITIAL_STATE } from '../utils/actionType';

const INITIAL_STATE = {
  usuario: null,
  userId: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_RESULT:
      return { ...state, usuario: payload.usuario, userId: payload.userId };
    case SET_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
