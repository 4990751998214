import React from 'react';
import { Route, Router, Switch, Redirect, HashRouter, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';

import { isAuthenticated } from '../services/AuthService';
import { PublicLayout, PrivateLayout } from '../layouts';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

const hist = createBrowserHistory();

const PrivateRoutes = ({ token, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const PublicRoutes = ({ token, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

function Routes() {
  const token = useSelector(state => state.LoginReducer.token);
  return (
    <React.Suspense fallback={loading()}>
      <BrowserRouter>
        <Router history={hist}>
          <HashRouter>
            <Switch>
              <PrivateRoutes
                history={hist}
                token={token}
                path={isAuthenticated() ? '/' : '/painel'}
                component={props => <PrivateLayout {...props} />}
              />
              <PublicRoutes token={token} path="/" component={() => <PublicLayout />} />

            </Switch>
          </HashRouter>
        </Router>
      </BrowserRouter>
    </React.Suspense>
  );
}

export default Routes;
