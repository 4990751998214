import { Login, Loja, Perfil } from '../views';

const privateRoutes = [
  // {
  //   collapse: true,
  //   name: 'Dashboards',
  //   icon: 'ni ni-shop text-primary',
  //   state: 'dashboardsCollapse',
  //   views: [
  //     {
  //       path: '/dashboard',
  //       name: 'Dashboard',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     // {
  //     //   path: '/alternative-dashboard',
  //     //   name: 'Alternative',
  //     //   component: Login,
  //     //   layout: '/painel',
  //     // },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Examples',
  //   icon: 'ni ni-ungroup text-orange',
  //   state: 'examplesCollapse',
  //   views: [
  //     {
  //       path: '/pricing',
  //       name: 'Pricing',
  //       component: Login,
  //       layout: '/',
  //     },
  //     {
  //       path: '/login',
  //       name: 'Login',
  //       component: Login,
  //       layout: '/',
  //     },
  //     {
  //       path: '/register',
  //       name: 'Register',
  //       component: Login,
  //       layout: '/',
  //     },
  //     {
  //       path: '/lock',
  //       name: 'Lock',
  //       component: Login,
  //       layout: '/',
  //     },
  //     {
  //       path: '/timeline',
  //       name: 'Timeline',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     {
  //       path: '/profile',
  //       name: 'Profile',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Components',
  //   icon: 'ni ni-ui-04 text-info',
  //   state: 'componentsCollapse',
  //   views: [
  //     {
  //       path: '/buttons',
  //       name: 'Buttons',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     {
  //       path: '/cards',
  //       name: 'Cards',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     {
  //       path: '/grid',
  //       name: 'Grid',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     {
  //       path: '/notifications',
  //       name: 'Notifications',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     {
  //       path: '/icons',
  //       name: 'Icons',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     {
  //       path: '/typography',
  //       name: 'Typography',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     {
  //       collapse: true,
  //       name: 'Multi Level',
  //       state: 'multiCollapse',
  //       views: [
  //         {
  //           path: '#duduzera',
  //           name: 'Third level menu',
  //           component: () => {},
  //           layout: '/',
  //         },
  //         {
  //           path: '#duduzera',
  //           name: 'Just another link',
  //           component: () => {},
  //           layout: '/',
  //         },
  //         {
  //           path: '#duduzera',
  //           name: 'One last link',
  //           component: () => {},
  //           layout: '/',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Forms',
  //   icon: 'ni ni-single-copy-04 text-pink',
  //   state: 'formsCollapse',
  //   views: [
  //     {
  //       path: '/elements',
  //       name: 'Elements',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     {
  //       path: '/components',
  //       name: 'Components',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     {
  //       path: '/validation',
  //       name: 'Validation',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Tables',
  //   icon: 'ni ni-align-left-2 text-default',
  //   state: 'tablesCollapse',
  //   views: [
  //     {
  //       path: '/tables',
  //       name: 'Tables',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     {
  //       path: '/sortable',
  //       name: 'Sortable',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     {
  //       path: '/react-bs-table',
  //       name: 'React BS Tables',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: 'Maps',
  //   icon: 'ni ni-map-big text-primary',
  //   state: 'mapsCollapse',
  //   views: [
  //     {
  //       path: '/google',
  //       name: 'Google',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //     {
  //       path: '/vector',
  //       name: 'Vector',
  //       component: Login,
  //       layout: '/painel',
  //     },
  //   ],
  // },
  {
    path: '/loja',
    name: 'Loja de Coins',
    icon: 'ni ni-archive-2 text-green',
    component: Loja,
    layout: '/painel',
    showMenu: true,
  },
  {
    path: '/perfil',
    name: 'Charts',
    icon: 'ni ni-chart-pie-35 text-info',
    component: Perfil,
    layout: '/painel',
    showMenu: false,
  },
  // {
  //   path: '/calendar',
  //   name: 'Calendar',
  //   icon: 'ni ni-calendar-grid-58 text-red',
  //   component: Login,
  //   layout: '/painel',
  // },
];

const publicRoutes = [
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-archive-2 text-green',
    component: Login,
    layout: '/',
  },
  // {
  //   path: '/charts',
  //   name: 'Charts',
  //   icon: 'ni ni-chart-pie-35 text-info',
  //   component: Login,
  //   layout: '/',
  // },
  // {
  //   path: '/calendar',
  //   name: 'Calendar',
  //   icon: 'ni ni-calendar-grid-58 text-red',
  //   component: Login,
  //   layout: '/',
  // },
];

export { privateRoutes, publicRoutes };
