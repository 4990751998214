import * as ac from '../utils/actionType';
import ucp from '../api/ucp';

export const setProductCoinsList = payload => ({
  type: ac.STORE_SET_PRODUCT_LIST_COINS,
  payload,
});

export const getProductListByType = type => async dispatch => {
  try {
    const { data } = await ucp.get(`/products/${type}`);

    if (data) {
      dispatch(setProductCoinsList(data));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
