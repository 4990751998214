import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// plugins styles downloaded
import './assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import './assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import './assets/vendor/select2/dist/css/select2.min.css';
import './assets/vendor/quill/dist/quill.core.css';
import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
// core styles
import './assets/scss/theme.scss?v1.0.0';

import { store, persistor } from './config/store';
import Routes from './routes';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  );
}

export default App;
