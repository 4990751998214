import * as yup from 'yup';

export const loginValidator = yup.object().shape({
  username: yup
    .string()
    .required('Campo Obrigatório')
    .min(6, 'Mínimo 6 caracteres.')
    .nullable(),
  password: yup
    .string()
    .required('Campo Obrigatório')
    .min(6, 'Mínimo 6 caracteres.')
    .nullable(),
  captchaKey: yup.string().required('O Captcha é Obrigatório.'),
});

export const f = yup.object().shape({
  usuario: yup.string().required('Campo Obrigatório'),
});
