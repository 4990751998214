import ucp from '../api/ucp';

export async function comprarCoinsAction(pedido) {
  try {
    const { data } = await ucp.post('shopping/payment-request', pedido);
    if (data.url) {
      return data.url;
    }
    return false;
  } catch (error) {
    return null;
  }
}

export const teste = () => {};
