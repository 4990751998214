import { STORE_SET_PRODUCT_LIST_COINS, SET_INITIAL_STATE } from '../utils/actionType';

const INITIAL_STATE = {
  products: {
    coins: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_SET_PRODUCT_LIST_COINS:
      return { ...state, products: { ...state.products, coins: payload } };
    case SET_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
