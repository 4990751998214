import ucp from '../api/ucp';

import { setUserInfo } from './ProfileActions';
import { LOGIN_RESULT, SET_INITIAL_STATE } from '../utils/actionType';

export const setUsuarioAction = user => ({
  type: LOGIN_RESULT,
  payload: user,
});

export const LoginAction = (values, callback) => async dispatch => {
  const user = { ...values };

  try {
    const { data } = await ucp.post(`/auth/login`, user);
    if (data.user) {
      sessionStorage.setItem('Authorization', data.token);
      const userInfo = await dispatch(setUserInfo(data.user));
      if (userInfo && callback && typeof callback === 'function')
        callback({ sucesso: true, message: 'Logado com sucesso!' });
      else if (callback && typeof callback === 'function') {
        sessionStorage.removeItem('Authorization');
        callback({ sucesso: false, message: 'Erro ao acessar o servidor.' });
      }
    } else if (callback && typeof callback === 'function')
      callback({ sucesso: false, message: 'Usuário ou Senha Incorretos!' });
  } catch (error) {
    if (callback && typeof callback === 'function')
      callback({ sucesso: false, message: 'Erro ao acessar o servidor.' });
  }
};

export const LogoutAction = () => dispatch => {
  sessionStorage.removeItem('Authorization');
  dispatch({ type: SET_INITIAL_STATE });
};
