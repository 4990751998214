import React from 'react';

// reactstrap components
import { Button, Container, Row, Col } from 'reactstrap';

class ProfileHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className="header pb-6 d-flex align-items-center"
          style={{
            minHeight: '500px',
            backgroundImage: 'url("' + require('../../assets/img/theme/profile-cover.jpg') + '")',
            backgroundSize: 'cover',
            backgroundPosition: 'center top',
          }}>
          <span className="mask bg-gradient-info opacity-8" />

          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="7" md="10">
                <h1 className="display-2 text-white">Hello Jesse</h1>
                <p className="text-white mt-0 mb-5">
                  This is your profile page. You can see the progress you've made with your work and
                  manage your projects or assigned tasks
                </p>
                <Button
                  className="btn-neutral"
                  color="default"
                  href="#duduzera"
                  onClick={e => e.preventDefault()}>
                  Edit profile
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default ProfileHeader;
