/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Col, Button, Card, CardHeader, CardBody, ListGroup, ListGroupItem, Row } from 'reactstrap';

import { ScaleLoader } from 'react-spinners';
import { comprarCoinsAction } from '../../actions';
import { getProductListByType } from '../../actions/StoreActions';

function Loja() {
  const [coinsPack, setCoinsPack] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [payURL, setPayURL] = useState(null);
  const userInfo = useSelector(state => state.UsuarioReducer.userInfo);
  const products = useSelector(state => state.StoreReducer.products);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductListByType('coins'));
  }, []);

  async function finalizarCompra() {
    setIsLoading(true);
    const pack = {
      accountId: userInfo?.id,
      paymentMethod: 'MercadoPago',
      productId: coinsPack?.id,
    };
    const url = await comprarCoinsAction(pack);
    if (url) {
      setIsLoading(false);
      setPayURL(url);
    }
  }

  function realizarPagamento() {
    window.open(payURL, '_blank');

    setTimeout(() => {
      setPayURL(null);
      setCoinsPack(null);
    }, 1500);
  }

  function getProductList() {
    if (!products.coins.length) {
      return (
        <div className="loading-center">
          <ScaleLoader
            loading
            color="#5e72e4"
            radius="10px"
            size="20px"
            margin="2px"
            height="30px"
            width="5px"
          />
        </div>
      );
    }

    return (
      <ListGroup className="list my--3" flush>
        {products.coins.map(product => (
          <ListGroupItem className="px-0" key={product.id}>
            <Row className="align-items-center">
              <Col className="col-auto">
                <a
                  className="avatar rounded-circle"
                  href="#duduzera"
                  onClick={e => e.preventDefault()}>
                  <img
                    alt="..."
                    src="https://icons.iconarchive.com/icons/custom-icon-design/pretty-office-11/512/cash-icon.png"
                  />
                </a>
              </Col>
              <div className="col ml--2">
                <h4 className="mb-0">{product.description}</h4>
                <small>
                  <span className="text-success">●</span> R$
                  {product.price.toFixed(2).replace('.', ',')} ({product.bonus} Coins de Bônus)
                </small>
              </div>
              <Col className="col-auto">
                <Button
                  color="primary"
                  size="sm"
                  type="button"
                  onClick={() => setCoinsPack(product)}>
                  Comprar
                </Button>
              </Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  }

  return (
    <>
      <div className="card shadow">
        <div className="card-body">
          <div className="tab-content" id="myTabContent">
            <ul
              className="nav nav-pills nav-fill flex-column flex-md-row"
              id="tabs-icons-text"
              role="tablist">
              <li className="nav-item">
                <div
                  className={`nav-link mb-sm-3 mb-md-0 ${coinsPack === null && 'active'}`}
                  id="tabs-icons-text-1-tab"
                  data-toggle="tab"
                  href="#tabs-icons-text-1"
                  role="tab"
                  aria-controls="tabs-icons-text-1"
                  aria-selected="true">
                  <i className="ni ni-shop mr-2"></i>Produtos
                </div>
              </li>
              <li className="nav-item">
                <div
                  className={`nav-link mb-sm-3 mb-md-0u ${
                    !coinsPack?.coins_amount || coinsPack?.isFinalizar ? '' : 'active'
                  }`}
                  id="tabs-icons-text-2-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="tabs-icons-text-2"
                  aria-selected="false">
                  <i className="ni ni-cart mr-2"></i>Carrinho
                </div>
              </li>
              <li className="nav-item">
                <div
                  className={`nav-link mb-sm-3 mb-md-0 ${coinsPack?.isFinalizar && 'active'}`}
                  id="tabs-icons-text-3-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="tabs-icons-text-3"
                  aria-selected="false">
                  <i className="ni ni-check-bold mr-2"></i>Finalizar
                </div>
              </li>
            </ul>
            <div
              className={`tab-pane fade show ${coinsPack === null && 'active'}`}
              id="tabs-icons-text-1"
              role="tabpanel"
              aria-labelledby="tabs-icons-text-1-tab">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Selecione seu pacote de coins</h5>
                </CardHeader>

                <CardBody>{getProductList()}</CardBody>
              </Card>
            </div>
            <div
              className={`tab-pane fade ${
                !coinsPack?.coins_amount || coinsPack?.isFinalizar ? '' : 'show active'
              }`}
              id="tabs-icons-text-2"
              role="tabpanel"
              aria-labelledby="tabs-icons-text-2-tab">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Seu Carrinho</h5>
                </CardHeader>
                <CardBody>
                  <ListGroup className="list my--3" flush>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <a
                            className="avatar rounded-circle"
                            href="#duduzera"
                            onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              src="https://icons.iconarchive.com/icons/custom-icon-design/pretty-office-11/512/cash-icon.png"
                            />
                          </a>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">{coinsPack?.description}</h4>
                          <span className={coinsPack?.bonus > 0 ? 'text-success' : 'text-danger'}>
                            ●
                          </span>{' '}
                          <small>
                            R${coinsPack?.price.toFixed(2).replace('.', ',')} ({coinsPack?.bonus}{' '}
                            Coins de Bônus)
                          </small>
                        </div>
                        <Col className="col-auto">
                          <Button
                            color="primary"
                            size="sm"
                            type="button"
                            onClick={() => {
                              setCoinsPack({ ...coinsPack, isFinalizar: true });
                            }}>
                            Confirmar
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
              <div className="text-center">
                <Button
                  color="default"
                  size="sm"
                  type="button"
                  onClick={() => {
                    setCoinsPack(null);
                  }}>
                  Voltar
                </Button>
              </div>
            </div>
            <div
              className={`tab-pane fade ${coinsPack?.isFinalizar && 'show active'}`}
              id="tabs-icons-text-3"
              role="tabpanel"
              aria-labelledby="tabs-icons-text-3-tab">
              <Card>
                <CardHeader>
                  <h5 className="h3 mb-0">Resumo do seu pedido</h5>
                </CardHeader>
                <CardBody>
                  <ListGroup className="list my--3" flush>
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        {/* <Col className="col-auto">
                          <a
                            className="avatar rounded-circle"
                            href="#duduzera"
                            onClick={e => e.preventDefault()}>
                            <img alt="..." src={require('../../assets/img/theme/team-1.jpg')} />
                          </a>
                        </Col> */}
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            {coinsPack?.description} + {coinsPack?.bonus} coins de Bônus
                          </h4>
                          <h5 className="mb-0">
                            Total: R$
                            {coinsPack?.price.toFixed(2).replace('.', ',')}
                          </h5>
                        </div>
                        <Col className="col-sm-auto">
                          {isLoading ? (
                            <ScaleLoader
                              loading
                              color="#5e72e4"
                              radius="10px"
                              size="20px"
                              margin="2px"
                              height="30px"
                              width="5px"
                            />
                          ) : payURL ? (
                            <Button
                              color="success"
                              size="sm"
                              type="button"
                              onClick={() => realizarPagamento()}>
                              Realizar pagamento
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              size="sm"
                              type="button"
                              onClick={() => finalizarCompra()}>
                              Finalizar Compra
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </ListGroup>
                </CardBody>
              </Card>
              <div className="text-center">
                <Button
                  color="default"
                  size="sm"
                  type="button"
                  onClick={() => {
                    setCoinsPack({ ...coinsPack, isFinalizar: false });
                    setPayURL(null);
                  }}>
                  Voltar
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-wrapper"></div>
      </div>
    </>
  );
}

export default Loja;
