import * as ac from '../utils/actionType';
import ucp from '../api/ucp';

export const setUserInfo = payload => ({
  type: ac.USUARIO_SET_USER_INFO,
  payload,
});

export const getUserInfo = userId => async dispatch => {
  try {
    const { data } = await ucp.get(`/user/${userId}/info`);
    if (data.usuario) {
      dispatch(setUserInfo(data.usuario));
      return true;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export function setUserOrgInfo(payload) {
  return { type: ac.USUARIO_SET_ORGANIZATION_INFO, payload };
}

export const getOrganizationInfo = userId => async dispatch => {
  try {
    const { data } = await ucp.get(`/user/${userId}/organization`);
    if (data) {
      dispatch(setUserOrgInfo(data.organization));
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export function setUserTwistedInfo(twisted) {
  return {
    type: ac.USUARIO_SET_TWISTED_INFO,
    payload: twisted,
  };
}

export const getTwistedInfo = userId => async dispatch => {
  try {
    const { data } = await ucp.get(`/user/${userId}/twisted`);
    if (data.twisted) {
      dispatch(setUserTwistedInfo(data.twisted));
    }
  } catch (error) {
    return false;
  }
};
