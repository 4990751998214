import { combineReducers } from 'redux';
import LoginReducer from './LoginReducer';
import UsuarioReducer from './UsuarioReducer';
import StoreReducer from './StoreReducer';

const rootReducer = combineReducers({
  LoginReducer,
  UsuarioReducer,
  StoreReducer,
});

export default rootReducer;
