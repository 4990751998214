import axios from 'axios';
import { getToken } from '../services/AuthService';

function ucp() {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(config => {
    const token = getToken();
    config.headers.Authorization = `Bearer ${token}` || '';
    config.data = config.data || null;

    return config;
  });

  return instance;
}

export default ucp();
