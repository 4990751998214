import {
  USUARIO_SET_USER_INFO,
  USUARIO_SET_ORGANIZATION_INFO,
  USUARIO_SET_TWISTED_INFO,
  SET_INITIAL_STATE,
} from '../utils/actionType';

const INITIAL_STATE = {
  userInfo: {},
  organizationInfo: {},
  twistedInfo: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case USUARIO_SET_USER_INFO:
      return { ...state, userInfo: payload };
    case USUARIO_SET_ORGANIZATION_INFO:
      return { ...state, organizationInfo: payload };
    case USUARIO_SET_TWISTED_INFO:
      return { ...state, twistedInfo: payload };
    case SET_INITIAL_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
