import React from 'react';

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

function Calendar() {
  return (
    <>
      <Container fluid>
        <footer className="footer pt-0">
          <Row className="align-items-center">
            <Col className="md-auto">
              <div className="copyright text-center text-muted">
                Painel de Controle de Usuários - GTA Torcidas © {new Date().getFullYear()}
                {' -'}
                <a
                  className="font-weight-bold ml-1"
                  href="https://github.com/Duduzera1997"
                  target="_blank">
                  DuDuH
                </a>
              </div>
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
}

export default Calendar;
